import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`Bolet de color ocre rosat amb capell petit, amb el marge molt enrotllat i llanut. Amb el temps s’obre i pot fer fins a 9 cm de diàmetre. El centre és poc pelut que contrasta amb els marges que ho són molt. Davall el capell trobem làmines molt atapeïdes, amb nombroses lamel·les de color rosa a crema rosat. El peu és més curt que el diàmetre del capell i atenuat a la base. Les espores són de color crema en massa, el·lipsoidals, amb berrugues i de 7-9 x 5,9-6,5 micres.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      